import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Typography } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  numberContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    fontWeight: 700,
  },
  text: {
    opacity: "0.6",
  },
}))

const Value = ({ heading, text }) => {
  const classes = useStyles()
  return (
    <div className={classes.numberContainer}>
      <Typography
        variant="h3"
        component="h3"
        classes={{ root: classes.heading }}
      >
        {heading}
      </Typography>
      <Typography variant="h6" component="h6" classes={{ root: classes.text }}>
        {text}
      </Typography>
    </div>
  )
}

export default Value
