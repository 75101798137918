import React from "react"
import Image from "gatsby-image"
import { makeStyles } from "@material-ui/core/styles"
import { Typography, Button } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  episode: {
    boxShadow: "8px 8px 8px rgba(0,0,0,0.3)",
    transition: "all 0.3s",
    "&:hover": {
      transform: "translateY(-7px)",
      boxShadow: "12px 12px 12px rgba(0,0,0,0.5)",
    },
  },
  textContent: {
    padding: "1rem 3rem",
    [theme.breakpoints.up("sm")]: {
      padding: "1rem",
    },
  },
  heading: {
    margin: "1rem 0",
    textTransform: "uppercase",
    fontWeight: 700,
  },
  subHeading: {
    marginBottom: "3rem",
    opacity: "0.8",
  },
}))

const Episode = ({ fluid, url, title }) => {
  const classes = useStyles()
  return (
    <div className={classes.episode}>
      <Image fluid={fluid} />
      <div className={classes.textContent}>
        <Typography
          variant="h4"
          component="h4"
          align="center"
          classes={{ root: classes.heading }}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          component="p"
          align="center"
          classes={{ root: classes.subHeading }}
        >
          by Roots Operator
        </Typography>
        <Button
          href={url}
          target="_blank"
          rel="noreferrer"
          fullWidth
          color="primary"
          variant="contained"
        >
          Anhören
        </Button>
      </div>
    </div>
  )
}

export default Episode
