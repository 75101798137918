import React from "react"
import { Element } from "react-scroll"
import Bounce from "react-reveal/Bounce"
import Zoom from "react-reveal/Zoom"
import { graphql, useStaticQuery } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Button } from "@material-ui/core"
import Section from "../Layout/Section"
import Episode from "./Episode"
import * as LINKS from "../../constants/links"

const useStyles = makeStyles(theme => ({
  gridContainer: {
    padding: "0 0 8rem 0",
  },
  gridItem: {
    margin: "0 auto",
    [theme.breakpoints.up("sm")]: {
      margin: 0,
      padding: "2rem",
    },
  },
  buttonWrapper: {
    width: "100%",
    textAlign: "center",
  },
}))

const query = graphql`
  query getEpisodes {
    episodes: allContentfulFolge(
      limit: 6
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        image {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        id
        url
        title
      }
    }
  }
`

const Episodes = () => {
  const data = useStaticQuery(query)
  const episodes = data.episodes.nodes
  const classes = useStyles()
  return (
    <Element name="episodes" id="episodes">
      <Section secondBG title="Letzte Sendungen">
        <Grid container spacing={10} classes={{ root: classes.gridContainer }}>
          {episodes.map(episode => {
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={episode.id}
                classes={{ root: classes.gridItem }}
              >
                <Bounce left>
                  <Episode
                    fluid={episode.image.fluid}
                    title={episode.title}
                    url={episode.url}
                  />
                </Bounce>
              </Grid>
            )
          })}
        </Grid>
        <div className={classes.buttonWrapper}>
          <Zoom right>
            <Button
              variant="contained"
              href={LINKS.PODOMATIC}
              target="_blank"
              color="primary"
            >
              Alle Sendungen
            </Button>
          </Zoom>
        </div>
      </Section>
    </Element>
  )
}

export default Episodes
