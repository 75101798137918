import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Banner from '../Layout/Banner'
import Value from "./Value"

const useStyles = makeStyles(theme => ({
  gridContainer: {
    margin: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      padding: "3rem 0",
    },
  },
  gridItem: {
    display: "flex",
    justifyContent: "center",
  },
}))

const query = graphql`
  query getNumberBannerBG {
    file(relativePath: { eq: "vishnu-r-nair-pexels.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const VALUES = [
  { heading: "200+", text: "Sendungen" },
  { heading: "1995", text: "Gegründet" },
  { heading: "101%", text: "Reggae Vibrations" },
  { heading: "1200+", text: "Gespielte Minuten" },
]

const NumberBanner = () => {
  const classes = useStyles()
  const {
    file: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(query)

  return (
    <Banner imageSrc={fluid}>
      <Grid container spacing={5} classes={{ root: classes.gridContainer }}>
        {VALUES.map(value => (
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            classes={{ root: classes.gridItem }}
            key={value.text}
          >
            <Value heading={value.heading} text={value.text} />
          </Grid>
        ))}
      </Grid>
    </Banner>
  )
}

export default NumberBanner
