import React from "react"
import MainLayout from "../components/Layout/MainLayout"
import SEO from "../components/Seo/"
import HomeAbout from "../components/About/HomeAbout"
import Episodes from "../components/Episodes/"
import NumberBanner from "../components/Banner/NumberBanner"
import Contact from "../components/Contact/"

const IndexPage = ({ location }) => {
  return (
    <MainLayout location={location}>
      <SEO title="Home" />
      <HomeAbout />
      <Episodes />
      <NumberBanner />
      <Contact />
    </MainLayout>
  )
}

export default IndexPage
