import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import * as ROUTES from "../../constants/routes"
import Section from "../Layout/Section"
import ContentBox from "../ContentBox/"

const query = graphql`
  query getData {
    data: contentfulHomeAboutSection {
      image {
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      text {
        childMdx {
          body
        }
      }
    }
  }
`

const HomeAbout = () => {
  const { data } = useStaticQuery(query)
  return (
    <Section title="Über Cool & Deadly">
      <ContentBox
        text={data.text.childMdx.body}
        image={data.image.fluid}
        route={ROUTES.ABOUT}
        buttonText="Mehr Erfahren"
      />
    </Section>
  )
}

export default HomeAbout
