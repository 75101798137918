import React from "react"
import { Element } from "react-scroll"
import { makeStyles } from "@material-ui/core/styles"
import { Typography, useMediaQuery, useTheme } from "@material-ui/core"
import Section from "../Layout/Section"

const useStyles = makeStyles(theme => ({
  text: {
    marginBottom: "2rem",
    opacity: "0.9",
  },
  email: {
    marginTop: "3rem",
    color: theme.palette.warning.main,
  },
}))

const Contact = () => {
  const classes = useStyles()
  const theme = useTheme()
  const downSM = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Element name="contact" id="contact">
      <Section title="Kontakt">
        <Typography
          variant="body1"
          component="p"
          align="center"
          classes={{ root: classes.text }}
        >
          Da der musikalische Support in meiner Sendung ganz weit oben steht,
          freue ich mich immer über Jingles, Tunes, Demos und Dubplates.
          Feedback und Kritik nehme ich ebenfalls gerne an, um ein noch besseres
          Hörerlebnis zu schaffen.
        </Typography>
        <Typography
          variant="body1"
          component="p"
          align="center"
          classes={{ root: classes.text }}
        >
          {" "}
          Daher schreibt mir gerne persönlich unter:
        </Typography>
        <Typography
          variant={downSM ? "h5" : "h4"}
          component={downSM ? "h5" : "h4"}
          align="center"
          classes={{ root: classes.email }}
        >
          cool_and_deadly@gmx.net
        </Typography>
      </Section>
    </Element>
  )
}

export default Contact
